import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/schedule/Schedule.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/create-client',
      name: 'create-client',
      component: () => import('@/views/clients/CreateClient.vue'),
      meta: {
        pageTitle: 'Stwórz klienta',
        breadcrumb: [
          {
            text: 'Stwórz klienta',
            active: true,
          },
        ],
      },
    },
    {
      path: '/dashboard',
      name: 'dashboard',
        component: () => import('@/views/Home.vue'),
        meta: {
            pageTitle: 'Dashboard',
            breadcrumb: [
                {
                    text: 'Dashboard',
                    active: true,
                }
            ],
        },
    },
    {
      path: '/Pracownicy',
      name: 'Eployees',
      component: () => import('@/views/SecondPage.vue'),
      meta: {
        pageTitle: 'Pracownicy',
        breadcrumb: [
          {
            text: 'Pracownicy',
            active: true,
          },
        ],
      },
    },
    {
      path: '/Widok-pracownika',
      name: 'EmployeeView',
      component: () => import('@/views/employeeview/EmployeeView.vue'),
      // clear sitebar menu
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/Widok-pracownika/Klient/:id',
      name: 'EmployeeClientView',
      component: () => import('@/views/employeeview/EmployeeClientView.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/Pracownicy/dodaj',
      name: 'Employee-add',
      component: () => import('@/views/employees/EmployeeAdd.vue'),
      meta: {
        pageTitle: 'Dodaj pracownika',
        breadcrumb: [
          {
            text: 'Pracownicy',
          },
        ],
      },
    },
    {
      path: '/Pracownicy/:id',
      name: 'Employee-show',
      component: () => import('@/views/employees/EmployeeShow.vue'),
      meta: {
        pageTitle: 'Pracownik',
        breadcrumb: [
          {
            text: 'Pracownicy',
            link: '/Pracownicy',
          },
        ],
      },
    },
    {
      path: '/profil',
      name: 'profile',
      component: () => import('@/views/Profile.vue'),
      meta: {
        pageTitle: 'Profile',
        breadcrumb: [
          {
            text: 'Profile',
            active: true,
          },
        ],
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/Podsumowanie-dzienne',
      name: 'DailySummarySelectEmployee',
      component: () => import('@/views/summarizing/EmployeeList.vue'),
      meta: {
        pageTitle: 'Raporty',
        breadcrumb: [
          {
            text: 'Podsumowanie',
            active: true,
          },
        ],
      },
    },
    {
      path: '/Zamowienie/:id',
      name: 'Order-show',
      component: () => import('@/views/orders/OrderShow.vue'),
      meta: {
        pageTitle: 'Zamówienie',
        breadcrumb: [
          {
            text: 'Zamówienia',
            route: 'Orders',
            active: false,
          },
        ],
      },
    },
    {
      path: '/Podsumowanie-dzienne/:id',
      name: 'DailySummary',
      component: () => import('@/views/summarizing/DailySummary.vue'),
      meta: {
        pageTitle: 'Podsumowanie',
        breadcrumb: [
          {
            text: 'podsumowanie',
            active: true,
          },
        ],
      },
    },
    {
      path: '/Oferta/godziny-otwarcia/:id',
      name: 'OfferDate',
      component: () => import('@/views/offers/OfferDate.vue'),
      meta: {
        pageTitle: 'Godziny otwarcia',
        breadcrumb: [
          {
            text: 'Godziny otwarcia',
            active: true,
          },
        ],
      },
    },
    {
      path: '/Podsumowanie-miesięczne',
      name: 'MonthlySummary',
      component: () => import('@/views/summarizing/MonthlySummary.vue'),
      meta: {
        pageTitle: 'Podsumowanie',
        breadcrumb: [
          {
            text: 'Podsumowanie',
            active: true,
          },
        ],
      },
    },
    {
      path: '/oferty',
      name: 'Offers',
      component: () => import('@/views/offers/Offers.vue'),
      meta: {
        pageTitle: 'Usługi',
        breadcrumb: [
          {
            text: 'Usługi',
            active: true,
          },
        ],
      },
    },
    {
      path: '/Ustawienia-biznesu',
      name: 'BusinessSettings',
      component: () => import('@/views/business/BusinessSettings.vue'),
      meta: {
        pageTitle: 'Ustawienia biznesu',
        breadcrumb: [
          {
            text: 'Ustawienia biznesu',
            active: true,
          },
        ],
      },
    },
    {
      path: '/oferty/dodaj',
      name: 'Offer-add',
      component: () => import('@/views/offers/OfferAdd.vue'),
      meta: {
        pageTitle: 'Dodaj usługę',
        breadcrumb: [
          {
            text: 'Usługi',
            route: 'Offers',
            active: false,
          },
        ],
      },
    },
    {
      path: '/Happy-hours/dodaj',
      name: 'HappyHoursAdd',
      component: () => import('@/views/promotions/HappyHourAdd.vue'),
      meta: {
        pageTitle: 'Dodaj Happy Hour',
        breadcrumb: [
          {
            text: 'Happy Hours',
            route: 'HappyHours',
            active: false,
          },
        ],
      },
    },
    {
      path: '/oferty/:id',
      name: 'Offer-show',
      component: () => import('@/views/offers/OfferShow.vue'),
      meta: {
        pageTitle: 'Usługa',
        breadcrumb: [
          {
            text: 'Usługi',
            route: 'Offer',
            active: false,
          },
        ],
      },
    },
    {
      path: '/oferty/:id/edytuj',
      name: 'Offer-show',
      component: () => import('@/views/offers/EditOffer.vue'),
      meta: {
        pageTitle: 'Edycja usługi',
        breadcrumb: [
          {
            text: 'Edycja usługi',
            route: 'OfferEdit',
            active: false,
          },
        ],
      },
    },
    {
      path: '/Klienci',
      name: 'Clients',
      component: () => import('@/views/clients/Clients.vue'),
      meta: {
        pageTitle: 'Klienci',
        breadcrumb: [
          {
            text: 'Klienci',
            active: true,
          },
        ],
      },
    },
    {
      path: '/Klient/:id',
      name: 'Client-show',
      component: () => import('@/views/clients/ClientShow.vue'),
      meta: {
        pageTitle: 'Klient',
        breadcrumb: [
          {
            text: 'Klienci',
            route: 'Clients',
            active: false,
          },
        ],
      },

    },
    {
      path: '/data/:id',
      name: 'Date-show',
      component: () => import('@/views/offers/DateShow.vue'),
      meta: {
        pageTitle: 'Data',
        breadcrumb: [
          {
            text: 'Data',
            route: 'Date',
            active: false,
          },
        ],
      },
    },
    {
      path: '/Wiadomosci',
      name: 'Messages',
      component: () => import('@/views/messages/Select.vue'),
      meta: {
        pageTitle: 'Wiadomości',
        breadcrumb: [
          {
            text: 'Wiadomości',
            active: true,
          },
        ],
      },
    },
    {
      path: '/Wiadomosci/:id',
      name: 'Message-show',
      component: () => import('@/views/messages/MessageShow.vue'),
      meta: {
        pageTitle: 'Wiadomość',
        breadcrumb: [
          {
            text: 'Wiadomości',
            route: 'Messages',
            active: false,
          },
        ],
      },
    },
    {
      path: '/grafik',
      name: 'Schedule',
      component: () => import('@/views/schedule/Schedule.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/grafik/:id',
      name: 'Schedule-show',
      component: () => import('@/views/schedule/ScheduleEmployee.vue'),
      meta: {
        pageTitle: 'Grafik',
        breadcrumb: [
          {
            text: 'Grafik',
            route: 'Schedule',
            active: false,
          },
        ],
      },
    },
    {
      path: '/Godziny-pracy/:id',
      name: 'WorkHours-show',
      component: () => import('@/views/employees/WorkHoursShow.vue'),
      meta: {
        pageTitle: 'Godziny pracy',
        breadcrumb: [
          {
            text: 'Godziny pracy',
            route: 'WorkHours',
            active: false,
          },
        ],
      },
    },
    {
      path: '/magazyn',
      name: 'Warehouse',
      component: () => import('@/views/warehouse/Warehouse.vue'),
      meta: {
        pageTitle: 'Magazyn',
        breadcrumb: [
          {
            text: 'Magazyn',
            route: 'Warehouse',
            active: false,
          },
        ],
      },
    },
    {
      path: '/magazyn/:id',
      name: 'Warehouse-show',
      component: () => import('@/views/warehouse/WarehouseShow.vue'),
      meta: {
        pageTitle: 'Magazyn',
        breadcrumb: [
          {
            text: 'Magazyn',
            route: 'Warehouse',
            active: false,
          },
        ],
      },
    },
    {
      path: '/Dodaj-przedmiot-magazynowy',
      name: 'Warehouse-add',
      component: () => import('@/views/warehouse/WarehouseAdd.vue'),
      meta: {
        pageTitle: 'Dodaj przedmiot magazynowy',
        breadcrumb: [
          {
            text: 'Magazyn',
            route: 'Warehouse',
            active: false,
          },
        ],
      },
    },
    {
      path: '/Sprzet',
      name: 'Equipment',
      component: () => import('@/views/equipment/Equipment.vue'),
      meta: {
        pageTitle: 'Sprzęt',
        breadcrumb: [
          {
            text: 'Sprzęt',
            route: 'Equipment',
            active: false,
          },
        ],
      },
    },
    {
      path: '/Sprzet/:id',
      name: 'Equipment-show',
      component: () => import('@/views/equipment/EquipmentShow.vue'),
      meta: {
        pageTitle: 'Sprzęt',
        breadcrumb: [
          {
            text: 'Sprzęt',
            route: 'Equipment',
            active: false,
          },
        ],
      },
    },
    {
      path: '/Dodaj-sprzet',
      name: 'Equipment-add',
      component: () => import('@/views/equipment/EquipmentAdd.vue'),
      meta: {
        pageTitle: 'Dodaj sprzęt',
        breadcrumb: [
          {
            text: 'Sprzęt',
            route: 'Equipment',
            active: false,
          },
        ],
      },
    },
    {
      path: '/Promocje',
      name: 'Promotions',
      component: () => import('@/views/promotions/Promotions.vue'),
      meta: {
        pageTitle: 'Promocje',
        breadcrumb: [
          {
            text: 'Promocje',
            route: 'Promotions',
            active: false,
          },
        ],
      },
    },
    {
      path: '/Promocje/:id',
      name: 'Promotions-edit',
      component: () => import('@/views/promotions/PromotionEdit.vue'),
      meta: {
        pageTitle: 'Promocje',
        breadcrumb: [
          {
            text: 'Promocje',
            route: 'Promotions',
            active: false,
          },
        ],
      },
    },
    {
      path: '/Promocje/dodaj',
      name: 'Promotions-add',
      component: () => import('@/views/promotions/PromotionsAdd.vue'),
      meta: {
        pageTitle: 'Dodaj promocję',
        breadcrumb: [
          {
            text: 'Promocje',
            route: 'Promotions',
            active: false,
          },
        ],
      },
    },
    {
      path: '/Bank',
      name: 'Bank',
      component: () => import('@/views/bank/BankDashboard.vue'),
      meta: {
        pageTitle: 'Bank',
        breadcrumb: [
          {
            text: 'Bank',
            route: 'Bank',
            active: false,
          },
        ],
      },
    },
    {
      path: '/rejestracja',
      name: 'rejestracja',
      component: () => import('@/views/Register.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/Import',
      name: 'Import',
      component: () => import('@/views/Import.vue'),
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
    {
      path: '/Lista-rezerwowa',
      name: 'ReserveList',
      component: () => import('@/views/reserve/ReserveList.vue'),
      meta: {
        pageTitle: 'Lista rezerwowa',
        breadcrumb: [
          {
            text: 'Lista rezerwowa',
            route: 'ReserveList',
            active: false,
          },
        ],
      },
    },
    {
      path: '/Dodaj-wizyte',
      name: 'VisitAdd',
      component: () => import('@/views/reserve/AddVisit.vue'),
      meta: {
        pageTitle: 'Dodaj wizytę',
        breadcrumb: [
          {
            text: 'Dodaj wizytę',
            route: 'VisitAdd',
            active: false,
          },
        ],
      },
    },
    {
      path: '/Dodaj-rezerwacje-czasu',
      name: 'TimeReservationAdd',
      component: () => import('@/views/AddTimeReservation.vue'),
      meta: {
        pageTitle: 'Dodaj rezerwację czasu',
        breadcrumb: [
          {
            text: 'Dodaj rezerwację czasu',
            route: 'TimeReservationAdd',
            active: false,
          },
        ],
      },
    },
    {
      path: '/Dodaj-cylkiczna-wizyte',
      name: 'VisitCyclicAdd',
      component: () => import('@/views/AddCyclicVisit.vue'),
      meta: {
        pageTitle: 'Dodaj cykliczną wizytę',
        breadcrumb: [
          {
            text: 'Dodaj cykliczną wizytę',
            route: 'VisitCyclicAdd',
            active: false,
          },
        ],
      },
    },
    {
      path: '/faktury',
      name: 'Invoices',
      component: () => import('@/views/invoices/Invoices.vue'),
      meta: {
        pageTitle: 'Faktury',
        breadcrumb: [
          {
            text: 'Faktury',
            route: 'Invoices',
            active: false,
          },
        ],
      },
    },
    {
      path: '/import-cvs',
      name: 'Import-cvs',
      component: () => import('@/views/Import.vue'),
      meta: {
        pageTitle: 'Import cvs',
        breadcrumb: [
          {
            text: 'Import cvs',
            route: 'Import-cvs',
            active: false,
          },
        ],
      },
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
